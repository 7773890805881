import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { library } from '@fortawesome/fontawesome-svg-core'
const cors = require('cors')

import {faExclamationTriangle, faEnvelope, faXmark, faCheck } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faExclamationTriangle, faEnvelope, faXmark, faCheck)

const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(store);
app.use(router);

app.mount('#app')
